.events-map {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  .trend {
    display: inline-block;
    position: absolute;
    height: 100%;
    pointer-events: all;
    cursor: pointer;
    top: 0;
    width: 10px;

    &.upper {
      z-index: 1;
    }

    &.under {
      z-index: -1;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;

    .header-row {
      flex-grow: 0;
      width: calc(100% - 16px);
    }

    .grid-row {
      height: 50%;
      flex-grow: 1;
      width: 100%;
      position: relative;

      .date-slider {
        position: absolute;
        display: inline-block;
        height: 100%;
        width: 5px;
        top: 0px;
        pointer-events: all;

        .wrapper {
          position: relative;

          &.down-up {
            transform: rotate(-90deg) translate(-100%, -50%);
            transform-origin: top left;
          }
        }

        $track-color: #dbe1d7;
        $thumb-color: #48aafc;

        $thumb-radius: 6px;
        $thumb-height: 12px;
        $thumb-width: 12px;
        $thumb-shadow-size: 1px;
        $thumb-shadow-blur: 1px;
        $thumb-shadow-color: #111;
        $thumb-border-width: 1px;
        $thumb-border-color: $thumb-color;

        $track-width: 100%;
        $track-height: 2px;
        $track-shadow-size: 2px;
        $track-shadow-blur: 2px;
        $track-shadow-color: #222;
        $track-border-width: 0px;
        $track-border-color: black;

        $track-radius: 5px;
        $contrast: 5%;

        @mixin shadow($shadow-size,$shadow-blur,$shadow-color) {
          box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0px 0px $shadow-size lighten($shadow-color, 5%);
        }

        @mixin track() {
          width: $track-width;
          height: $track-height;
          cursor: pointer;
          animate: 0.2s;
        }

        @mixin thumb() {
          border: $thumb-border-width solid $thumb-border-color;
          height: $thumb-height;
          width: $thumb-width;
          border-radius: $thumb-radius;
          background: $thumb-color;
          cursor: pointer;
        }

        .input {
          -webkit-appearance: none;
          margin: 0;
          width: $track-width;
          pointer-events: auto;

          &:focus {
            outline: none;
          }

          &::-webkit-slider-runnable-track {
            @include track();
            background: $track-color;
            border-radius: $track-radius;
            border: $track-border-width solid $track-border-color;
          }

          &::-webkit-slider-thumb {
            @include thumb();
            -webkit-appearance: none;
            margin-top: ((-$track-border-width * 2 + $track-height) / 2) - ($thumb-height / 2);
          }

          &:focus::-webkit-slider-runnable-track {
            background: lighten($track-color, $contrast);
          }

          &::-moz-range-track {
            @include track();

            background: $track-color;
            border-radius: $track-radius;
            border: $track-border-width solid $track-border-color;
          }

          &::-moz-range-thumb {
            @include thumb();
          }

          &::-ms-track {
            @include track();
            background: transparent;
            border-color: transparent;
            border-width: $thumb-width 0;
            color: transparent;
          }

          &::-ms-fill-lower {
            background: darken($track-color, $contrast);
            border: $track-border-width solid $track-border-color;
            border-radius: $track-radius*2;
          }

          &::-ms-fill-upper {
            background: $track-color;
            border: $track-border-width solid $track-border-color;
            border-radius: $track-radius*2;
          }

          &::-ms-thumb {
            @include thumb();
          }

          &:focus::-ms-fill-lower {
            background: $track-color;
          }

          &:focus::-ms-fill-upper {
            background: lighten($track-color, $contrast);
          }
        }
      }

      .grid-wrapper {
        position: relative;
        display: inline-block;
        height: 100%;
        width: calc(100% - 150px);
        /* border: 1px solid #d3d3d3; */
        overflow: hidden;

        .relations-canvas {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 10;
          top: 0;
          left: 0;
          pointer-events: none;
        }

        .fm-grid-scroll {
          position: relative;
          display: inline-block;
          height: 100%;
          width: 100%;
          border: 1px solid lightgray;
          overflow: hidden;

          .grid-row {
            max-width: 100%;
            display: block;
            white-space: nowrap;
          }

          .infinite-container {
            height: 100%;
            overflow: scroll;
          }

          .fm-grid-cell {
            display: inline-block;
            vertical-align: top;
            border: 1px solid lightgray;
            border-top: none;
            border-left: none;

            &.selected {
              background-color: lightgray;
            }

            &.highlighted {
              background-color: yellow;
            }

            .cell-placeholder {
              width: 100%;
              height: 100%;
              position: relative;

              &.minified {
                .control {
                  margin: unset;
                }

                .wrapper {
                  &.stacked {
                    .events-pane {
                      max-width: calc(100% - 9px);
                    }
                  }

                  &.single {
                    .events-pane {
                      max-width: 100%;
                    }
                  }
                }

                .events-quantity {
                  margin: unset;
                  padding: unset;

                  label {
                    margin: 0;
                    pointer-events: all;
                    cursor: pointer;
                  }
                }
              }

              .wrapper {
                align-items: start;

                .control {
                  width: 100%;
                }

                .events-pane {
                  display: flex;
                  flex-wrap: wrap;
                  align-content: flex-start;
                  height: 100%;
                  flex-grow: 1;
                }

                &.flat,
                &.single {
                  display: flex;
                  flex-wrap: wrap;

                  .column {
                    display: flex;
                    flex-wrap: wrap;
                  }
                }

                &.flat-stacked,
                &.stacked {
                  display: flex;
                  justify-content: space-between;
                  height: 100%;

                  .column {
                    display: flex;
                    flex-wrap: wrap;
                  }

                  .quantity-pane {
                    align-self: flex-end;
                  }
                }

                &.stacked {
                  .events-pane {
                    max-width: 180px;
                  }
                }

                &.single {
                  .events-pane {
                    max-width: 180px;
                  }
                }
              }

              .events-quantity {
                display: inline-block;
                text-align: center;
                vertical-align: bottom;
                background-color: rgb(255, 120, 104);
                border-radius: 5px;
                border-width: 0;
                font-weight: bold;
                cursor: pointer;
                color: white;
                pointer-events: all;
                margin: 2px;
                padding: 0.1rem 0.5rem;
                position: relative;

                label {
                  margin: 0;
                  pointer-events: all;
                  cursor: pointer;
                }
              }

              .events-list-popover {
                .popover-content {
                  padding: 0;
                }

                user-select: none;
                color: initial;
                width: 300px;
                max-width: 300px;
                z-index: 1039;
                pointer-events: all;
                position: relative;

                &.right {
                  transform: translateY(-50%);
                  left: 100%;
                  top: -9px;
                }

                &.left {
                  transform: translate(-100%, -100%);
                  left: 0;
                  top: 9px;
                }

                &.top-left {
                  transform: translate(-100%, -150%);
                  left: 10%;
                  top: 0;

                  & > .arrow {
                    top: calc(100% + 11px);
                    right: 2px;
                    margin-top: -11px;
                    border-bottom-width: 0;
                    border-bottom-color: #999;
                    border-top-color: rgba(0, 0, 0, .25);

                    &:after {
                      right: -10px;
                      bottom: 2px;
                      content: " ";
                      border-bottom-width: 0;
                      border-top-color: #fff;
                    }
                  }
                }

                .content {
                  display: block;
                  padding: 0;
                  margin: 0;
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 300px;

                  .items-list {
                    margin: 0;
                    border: 0;
                    list-style: none;
                    padding: 0;

                    .item {
                      background-color: aliceblue;
                      margin: 0 0 3px 0;
                      border-width: 1px;
                      border-style: solid;

                      & > .container-fluid {
                        padding: 0;
                      }

                      .row {
                        margin: 0;
                        padding: 0;

                        & > div {
                          margin: 0;
                          padding: 0;
                        }
                      }

                      .color-label {
                        height: 4px;
                      }

                      .title-label {
                        max-width: 135px;
                        text-overflow: ellipsis;
                      }

                      label {
                        font-size: 12px;
                        font-weight: normal;
                      }

                      &.selected {
                        border: #6495ed 2px solid;
                        outline: none;
                        box-shadow: inset 0 1px 1px #6f7e95, 0 0 8px #66d1e9;
                      }
                    }
                  }
                }
              }
            }

            .control {
              display: inline-block;
              position: relative;
              width: 100%;
              height: 100%;
              margin: 3px 3px 3px 3px;


              &.selected {
                outline: none;
                box-shadow: inset 0 1px 1px rgb(111, 126, 149), 0 0 8px rgb(102, 209, 233);
              }

              &.multi {
                width: 100%;

                .fm-grid-cell-control {
                  width: 90%;

                  .drag-anchor {
                    right: calc(17px + 6%);
                  }
                }
              }

              .fm-grid-cell-control {
                //background-color: #EDF2F6;
                border-radius: 4%;
                border: 1px solid #17A4FE;
                height: 100%;
                width: 100%;
                display: inline-block;
                z-index: 0;

                .title-row {
                  height: 70%;
                  max-height: 70%;
                  overflow: hidden;
                  max-width: 143px;
                }

                .additional-info-row {
                  text-align: center;
                }

                .c-col {
                  padding: 0;
                }

                .color-label {
                  height: 5px;
                }

                .drag-anchor {
                  pointer-events: all;
                  cursor: grab;
                  width: 6%;
                  height: 12%;
                  vertical-align: top;
                  border: 1px solid rgb(219, 225, 215);
                  position: absolute;
                  border-radius: 50%;
                  right: 6%;
                  top: calc(5px + 6%);
                  background-color: rgb(219, 225, 215);
                }

                .title-label {
                  padding: 5px 0 0 10px;
                  font-weight: 400;
                  line-height: 1em;
                  font-size: 13px;
                  word-wrap: break-word;
                  white-space: pre-line;
                  overflow: hidden;
                }

                .impact-label {
                  cursor: pointer;
                  font-weight: normal;
                  pointer-events: auto;
                  width: 18px;
                  border-radius: 10px;
                  text-align: center;

                  &.value-0 {
                    background-color: #ffff7f;
                  }

                  &.value-1 {
                    background-color: #ffff00;
                  }

                  &.value-2 {
                    background-color: #ffea00;
                  }

                  &.value-3 {
                    background-color: #ffd400;
                  }

                  &.value-4 {
                    background-color: #ffbf00;
                  }

                  &.value-5 {
                    background-color: #ffaa00;
                  }

                  &.value-6 {
                    background-color: #ff9500;
                  }

                  &.value-7 {
                    background-color: #ff7f00;
                  }

                  &.value-8 {
                    background-color: #ff5500;
                  }

                  &.value-9 {
                    background-color: #ff2a00;
                  }

                  &.value-10 {
                    background-color: #ff0000;
                  }

                  &.value-11 {
                    background-color: #ff0000;
                  }

                  &.value-12 {
                    background-color: #ff0000;
                  }
                }

                .probability-label {
                  color: #626262;
                  cursor: pointer;
                  font-weight: normal;
                  font-size: smaller;
                  pointer-events: auto;
                }

                $track-color: #dbe1d7;
                $thumb-color: #48aafc;

                $thumb-radius: 4px;
                $thumb-height: 10px;
                $thumb-width: 10px;
                $thumb-shadow-size: 1px;
                $thumb-shadow-blur: 1px;
                $thumb-shadow-color: #111;
                $thumb-border-width: 1px;
                $thumb-border-color: $thumb-color;

                $track-width: 100%;
                $track-height: 2px;
                $track-shadow-size: 2px;
                $track-shadow-blur: 2px;
                $track-shadow-color: #222;
                $track-border-width: 0px;
                $track-border-color: black;

                $track-radius: 5px;
                $contrast: 5%;

                @mixin shadow($shadow-size,$shadow-blur,$shadow-color) {
                  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0px 0px $shadow-size lighten($shadow-color, 5%);
                }

                @mixin track() {
                  width: $track-width;
                  height: $track-height;
                  cursor: pointer;
                  animate: 0.2s;
                }

                @mixin thumb() {
                  border: $thumb-border-width solid $thumb-border-color;
                  height: $thumb-height;
                  width: $thumb-width;
                  border-radius: $thumb-radius;
                  background: $thumb-color;
                  cursor: pointer;
                }

                .probability-range,
                .impact-range,
                .date-slider .wrapper .input {
                  -webkit-appearance: none;
                  margin: 0;
                  width: $track-width;
                  pointer-events: auto;

                  &:focus {
                    outline: none;
                  }

                  &::-webkit-slider-runnable-track {
                    @include track();
                    background: $track-color;
                    border-radius: $track-radius;
                    border: $track-border-width solid $track-border-color;
                  }

                  &::-webkit-slider-thumb {
                    @include thumb();
                    -webkit-appearance: none;
                    margin-top: ((-$track-border-width * 2 + $track-height) / 2) - ($thumb-height / 2);
                  }

                  &:focus::-webkit-slider-runnable-track {
                    background: lighten($track-color, $contrast);
                  }

                  &::-moz-range-track {
                    @include track();

                    background: $track-color;
                    border-radius: $track-radius;
                    border: $track-border-width solid $track-border-color;
                  }

                  &::-moz-range-thumb {
                    @include thumb();
                  }

                  &::-ms-track {
                    @include track();
                    background: transparent;
                    border-color: transparent;
                    border-width: $thumb-width 0;
                    color: transparent;
                  }

                  &::-ms-fill-lower {
                    background: darken($track-color, $contrast);
                    border: $track-border-width solid $track-border-color;
                    border-radius: $track-radius*2;
                  }

                  &::-ms-fill-upper {
                    background: $track-color;
                    border: $track-border-width solid $track-border-color;
                    border-radius: $track-radius*2;
                  }

                  &::-ms-thumb {
                    @include thumb();
                  }

                  &:focus::-ms-fill-lower {
                    background: $track-color;
                  }

                  &:focus::-ms-fill-upper {
                    background: lighten($track-color, $contrast);
                  }
                }

                .probability-new-value,
                .impact-new-value {
                  font-size: 12px;
                  font-weight: 200;
                }

                .probability-edit-button,
                .impact-edit-button {
                  pointer-events: auto;
                  cursor: pointer;
                  width: 14px;
                  height: 16px;
                  line-height: 9px;
                  font-size: 9px;
                  font-weight: 200;
                  vertical-align: middle;
                  padding: 1px;

                  &.accept {

                  }

                  &.cancel {

                  }
                }

                .relations-marker {
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  z-index: 2;
                  border-left: 3px solid #ADFF2F;
                  border-right: 3px solid transparent;
                  border-bottom: 3px solid transparent;
                  border-top: 3px solid #ADFF2F;
                  border-radius: 3px;
                }

                .comments-label {
                  font-weight: normal;
                  font-size: smaller;

                  span {
                    margin-left: 1px;
                  }
                }
              }


              &.scale-1-5 {
                .fm-grid-cell-control {
                  .title-label {
                    font-weight: 400;
                    font-size: 18px;
                  }
                }
              }

              &.scale-1-25 {
                .fm-grid-cell-control {
                  .title-label {
                    font-weight: 400;
                    font-size: 16px;
                  }
                }
              }

              &.scale-0-75 {
                .fm-grid-cell-control {
                  .title-label {
                    font-weight: 200;
                    font-size: 12px;
                  }
                }
              }

              &.scale-0-5 {
                .fm-grid-cell-control {
                  .title-label {
                    font-weight: 200;
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .toggle-mini-map {
        position: absolute;
        bottom: 16px;
        right: 16px;
        padding: 2px 4px;
        font-size: 11px;
        line-height: 14px;
      }

      .mini-map {
        position: absolute;
        bottom: 16px;
        right: 16px;
        height: 30vh;
        width: 30vh;
        z-index: 10;
        background: transparent;

        .toggle-mini-map {
          position: relative;
          top: 0;
          left: 0;
          bottom: initial;
          right: initial;
          padding: 0 2px;
          font-size: 9px;
        }

        svg {
          width: 100%;
          height: calc(100% - 16px);
          background: white;
          border: 1px solid lightgray;
        }
      }
    }

    .controls-row {
      margin-top: 3px;
      align-self: flex-end;

      .increase-scale,
      .decrease-scale {
        padding: 2px 4px;
        line-height: 14px;
        float: right;
        font-size: 11px;
      }
    }
  }
}

.fm-vertical-headers-switcher_wrapper {
  position: relative;
  display: inline-block;
}

.fm-vertical-headers-switcher {

}

.fm-vertical-headers-scroll {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: calc(100% - 16px);
  text-align: right;
  width: 150px;
  border: 1px solid lightgray;
  border-right: none;
  border-left: none;
  overflow: hidden;
  pointer-events: none;

  .vertical-header-years {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 50px;
  }

  .vertical-header-months {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 50px;
  }

  .vertical-header-days {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 50px;
  }

  .row {
    margin: 0;
    display: block;
    white-space: nowrap;
  }

  .cell {
    display: inline-block;
    width: 50px;
    height: 100%;

    &.start {
      border-bottom: 1px solid lightgray;
    }

    &.active {
      label {
        font-size: 16px;
      }
    }

    border-left: 1px solid lightgray;

    &.center {
      label {
        font-size: 16px;
      }
    }

    .cell-content {
      display: table;
      margin: auto;
      height: 100%;
    }

    label {
      text-align: center;
      height: 100%;
      width: 100%;
      font-size: 0;
      vertical-align: middle;
      margin: 0;
      display: table-cell;
    }
  }

  .infinite-container {
    height: 100%;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.fm-horizontal-headers-scroll {
  position: relative;
  display: inline-block;
  border: 1px solid lightgray;
  border-bottom: none;
  width: calc(100% - 150px);
  overflow: hidden;

  .content {
    white-space: nowrap;
    background-color: #eaf2f5;
    display: inline-block;
  }

  .horizontal-header-item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    background-color: #eaf2f5;

    .sub {
      text-align: right;

      .column {
        display: inline-block;
        position: relative;
      }
    }

    label {
      margin: 0;
      z-index: 1;
    }

    .title-label {
      border-radius: 12px;
      padding: 5px;
      overflow: hidden;
      word-wrap: break-word;

      &.level-0 {
        background-color: #fdd9d4;
      }

      &.level-1 {
        background-color: #ebd1ef;
      }

      &.level-2 {
        background-color: #cceae7;
      }

      &.level-3 {
        background-color: #fcf9d0;
      }
    }
  }

  .connection {
    position: absolute;
  }
}

/* Переключатель периодов */
#fm-vertical-headers-switcher-content {
  border: 1px solid #667283;
  border-radius: 6px;
  background-color: #d9e4eb;
}

#fm-vertical-headers-switcher-content .c-col {
  padding: 0 0 0 5px;
  color: #667283;
}

#fm-vertical-headers-switcher-content input {
  margin-right: 5px;
}

/* Просмотр события */
.preview-modal {
  .modal-content {
    width: 820px;
    background-color: #eaf5fb;
  }

  .content {
    width: 100%;
    display: block;

    .trends {
      display: block;
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0px;
      right: 0px;

      .trend {
        display: inline-block;
        position: absolute;
        height: 100%;
        width: 10px;
      }
    }
  }
}

#preview-modal-body {
  width: 800px;
  padding: 10px;
}

#preview-modal-body-content .c-grid {
  padding: 0;
  margin: 0;
  width: 800px;
  background-color: #eaf5fb;
}

#preview-modal-body-content .c-row {
  margin: 0;
}

#preview-modal-body-content .c-row.caption {
  padding-bottom: 0;
}

#preview-modal-body-content .color-label {
  font-weight: bold;
  width: 5px;
}

#preview-modal-body-content .title-label {
  font-weight: bold;
  color: #000000;
  text-align: left;
  font-size: 12pt;
}

#preview-modal-body-content .caption-text {
  font-size: 8pt;
  color: #abb2ba;
}

#preview-modal-body-content .value-text {
  font-size: 10pt;
  color: #000000;
}

#preview-modal-body-content .horizontal-separator {
  height: 3px;
  background-color: #abb2ba;
}

#preview-modal-body-content .vertical-separator {
  width: 3px;
  background-color: #abb2ba;
}

#preview-modal-body-content #comments-glyph {
  width: 25px;
  display: inline-block;
}

#preview-modal-body-content .horizontal-separator.comments {
  margin-left: 25px;
}

#preview-modal-body-content table, #preview-modal-body-content th, #preview-modal-body-content td {
  border: 0;
}

#preview-modal-body-content table th {
  text-align: left;
}

#preview-modal-body-content table {
  overflow-y: scroll;
}