@import "~bootstrap/dist/css/bootstrap.css";
@import "~react-widgets/dist/css/react-widgets.css";
@import "~react-summernote/dist/react-summernote.css";
@import "~react-dropdown-tree-select/dist/styles.css";
@import "~vis/dist/vis-network.min.css";
@import "~rc-tree/dist/rc-tree.min.css";
@import "~rc-tree-select/assets/index.css";
@import "../css/react-contextmenu.css";
@import "layout";
@import "misc";
@import "controls";
@import "components";

@font-face {
  font-family: Roboto;
  font-style: normal;
  src: url("../fonts/Roboto-Regular.ttf");
}

.display-flex {
  display: flex;
}

.align-items-start {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: start;
}

.align-items-stretch {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-direction-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.p-1 {
  padding: 0.6rem;
}

.pt-1 {
  padding-top: 0.6rem;
}

.pb-1 {
  padding-bottom: 0.6rem;
}

.pl-1 {
  padding-left: 0.6rem;
}

.pr-1 {
  padding-right: 0.6rem;
}

.m-0 {
  margin: 0!important;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.m-1 {
  margin: 1px;
}

.mt-1 {
  margin-top: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.position-relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

html {
  body {
    font: 9pt Roboto, Helvetica, sans-serif;
    background: rgb(255, 255, 255);
  }
}

.rw-combobox {
  line-height: 26px;

}

.rw-widget-picker {
  height: 26px;

  .rw-dropdown-list-autofill, .rw-filter-input, .rw-input {
    padding: 0 6px;
  }
  .rw-btn {
    height: calc(1.2145em - 3px);
    line-height: calc(1.2145em - 3px);
  }

}

.btn,
.btn:hover,
.btn:active,
.btn:focus,
.btn:active:hover {
  user-select: none;
  outline: none;
}

.form-group {
  margin-bottom: 5px;
}

.form-control {
  height: 26px;
  padding: 3px 6px;
}

.form-horizontal .control-label {
  white-space: nowrap;
  padding-top: 4px;
}

.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
  padding-top: 3px;
}

.application-menu {
  background-color: #354052;
  color: #a0abbf;
  user-select: none;
  font-size: 11px;
  padding: 0;

  .logo-placeholder {
    text-align: center;
    padding: 20px;
  }
}

.row.toolbar,
.form-group.toolbar {
}

.row.content {
  overflow: hidden;
}

.login-page {
  width: 100%;
  height: 100%;
  position: relative;
}

.application-content {
  padding: 15px 0 10px 10px;

  .view {
    padding-right: 0;
  }
}

.separator-title-container {
  font-size: 14px;
  color: #5f6d85;
  margin: 4px 10px;
  padding: 4px 12px 2px;
}

table {
  table-layout: fixed;

  &,
  & th,
  & td {
    border: 1px solid #e8e8e8;
    word-wrap: break-word;
    font-size: 12px;
    font-family: "Helvetica Neue", sans-serif;
  }

  & th {
    font-family: "Roboto", sans-serif;
    text-align: center;
  }
}

table tbody tr:hover {
  background-color: lightgrey;
}

table > thead > tr > th {
  background: linear-gradient(0deg, rgb(236, 236, 236), rgba(236, 236, 236, 0.10));
  border-bottom: 1px solid #ddd;
}

table tr:nth-child(even) {
  background: rgba(236, 236, 236, 0.10);
}

.clickable {
  cursor: pointer;
}

.menu-item {
  margin: 10px 0;
}

.c-glyph-icon {
  margin-right: 10px;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: center;
  position: relative;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.action-button {
  background-color: #354052;
  border-radius: 3px;
  height: 32px;
  width: 120px;
  font-size: 11px;
  color: #a0abbf;
  margin: 0 5px;
  outline: none;

  &:focus,
  &:active:focus,
  &:active {
    outline: none;
    color: #a0abbf;
  }

  &:hover {
    color: #e9e8f3;
  }

  &.icon {
    padding: 0;
    text-align: left;

    .glyphicon {
      line-height: 31px;
      padding-left: 5px;
      padding-right: 5px;
      margin-left: 0;
      margin-right: 0;
      position: relative;
      top: 0;
      left: 0;
      border-right: 1px solid #21232363;
      text-align: center;
      width: 31px;

    }

    .label-wrapper {
      display: inline-block;
      white-space: nowrap;
      text-align: center;
      width: 87px;
      vertical-align: top;
    }

    label {
      font-weight: 400;
      line-height: 31px;
      margin: 0;
    }
  }

  nav &.icon {
    height: 30px;

    .glyphicon {
      line-height: 28px;
      width: 28px;
    }

    label {
      line-height: 28px;
    }
  }

  .form-group.buttons-bar & {
    float: right;
  }
}

.semi {
  width: 100px;
}

.c-navbar {
  position: relative;
  min-height: 50px;
  border: 1px solid transparent;
}

#login-form {
  width: 500px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.application-menu {
  .__rsnav___item {
    .rBgsU {
      width: 28px;
    }
  }
}

#project-info-tabs .tab-pane,
#factor-edit-tabs .tab-pane,
#event-edit-tabs .tab-pane,
#scenario-tabs .tab-pane {
  height: 100%;
}

.alert-item {
  .help-button {
    padding: 3px 12px;
  }
}

.news-item {
  .project-title {
    font-size: 12px;
    color: rgb(128, 128, 128);
  }

  &.action {
    background: #d20f0f38;
  }

  &.info {
    background: rgba(15, 116, 210, 0.22);
  }

  &.warning {
    background: rgba(210, 152, 15, 0.22);
  }

  .data {
    margin: 5px 0 5px 0;
    line-height: 16px;
    vertical-align: middle;

    .source {
      font-size: 14px;
      padding-left: 5px;
    }

    .type {
      font-size: 10px;
    }

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 220px;
    }
  }

  .additional-data {
    font-size: 9px;
    color: rgb(128, 128, 128);
  }
}

.home-view {
  .description {
    width: 100%;
    height: calc(100% - 10px);
    margin-top: 10px;
    resize: none;
    border: 1px solid #e8e8e8;
    padding: 5px;
  }
}

.right-sidebar {
  flex-direction: column;

  & > button {
    color: #a0abbf;
    background-color: #354052;
    outline: none;
    position: relative;
    border-radius: 1px;
    padding: 50px 0;
    width: 100%;
    max-width: 25px;

    .title {
      line-height: 11px;
      font-size: 11px;
      display: block;
      transform: rotate(-90deg);
    }

    &:focus,
    &:hover,
    &:active:focus,
    &:active {
      color: white;
      outline: none;
    }
  }

  .overlay.content {
    background: white;
    border: 1px solid #ccc;
    z-index: 10;
    max-height: 100%;
    overflow: hidden;
  }
}


tr.selected,
tr:hover.selected,
tr.selected:nth-child(even) {
  background-color: #0096C9;
}

/* Раздел табов */
.c-tabs, .c-tab {
  background-color: rgb(225, 225, 225);
}

.tab-buttons-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0;
}

.tab-button {
  background-color: #354052;
  border-radius: 3%;
  height: 30px;
  width: 220px;
  color: #a0abbf;
  margin: 0 5px;
}

.input-group {
  .form-control {
    border-radius: 2px;
    height: 26px;
    padding: 2px 6px;
  }

  .input-group-btn {
    button {
      height: 26px;
    }
  }

  .rw-widget-container{
    border-radius: 4px 0 0 4px;
  }
}

.input-group-addon {
  font-size: 12px;
}

.input-group-btn {
  .glyphicon {
    top: -2px;
  }
}

.react-scrollable-list {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border: #ddd solid 1px;
}

.react-scrollable-list-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  background-color: #fff;
  border-bottom: #ddd solid 1px;
}

.rc-tree-select-enabled.rc-tree-select-focused .rc-tree-select-selection {
  border: 1px solid #ccc;
  box-shadow: none;
}

.rc-tree-select-dropdown {
  outline: none;
  z-index: 1060;

  [role="listbox"] {
    outline: none;
  }

  .rc-tree-select-tree-node-content-wrapper {
    cursor: pointer;

    &.rc-tree-select-tree-node-selected {
      background-color: #58aee5;
      border: 1px solid #58aee5;
      padding: 1px;
    }
  }
}

.rc-tree-select-tree li {
  margin: 5px;
}

.rc-tree-select {
  height: 26px;
  user-select: none;
  box-shadow: none;
  outline: none;

  .rc-tree-select-selection {
    height: 26px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &__rendered {
      line-height: 26px;
    }

    &:hover {
      border: 1px solid #ccc;
      box-shadow: none;
    }

    &__clear {
      top: 6px;
    }
  }

  &-arrow {
    height: 26px;
  }
}



#factors-map {
  //border: 1px solid #343a45;
  .collapse-toggle-overlay {
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }

  .progress-overlay {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: lightgray;
    top: 0;
    left: 0;
  }
}

.row.toolbar,
.form-group.toolbar {
  nav {
    background-color: #6f7e95;
    outline: none;
  }
}

.bar-button-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0;
}

.bar-button {
  font-size: 11px;
  background-color: #7a8aa5;
  color: #f5f7f6;
  margin: 0 2px;
}



#filter-popover .popover-content {
  padding: 0;
}

#filter-popover .popover-button {
  margin: 0 0 10px 0;
  height: 30px;
  width: 110px;
  background-color: #7a8aa5;
  color: #f5f7f6;
}

#scenario-popover .popover-content {
  padding: 0;
}

#scenario-popover .popover-button,
.export-import-popover .popover-button {
  margin: 0 0 10px 0;
  padding: 3px 5px;
  height: 30px;
  width: 120px;
  background-color: #7a8aa5;
  color: #f5f7f6;
}

/* Событие в списке событий */
.fm-grid-cell-list-item {

}

.fm-grid-cell-list-item .color-label {
  height: 3px;
}

.fm-grid-cell-list-item .date-label {

}

.fm-grid-cell-list-item .title-label {
  overflow: hidden;
  text-align: center;
}

.fm-grid-cell-list-item .impact-label {

}

.fm-grid-cell-list-item .probability-label {

}

/* Карта событий. Горизонтальные заголовки */
#fm-horizontal-headers-scroll div[id^="col-header-"] {
  background-color: #eaf2f5;
}

#fm-horizontal-headers-scroll div[id^="col-header-"] .title-label-level-0 {
  background-color: #fdd9d4;
}

#fm-horizontal-headers-scroll div[id^="col-header-"] .title-label-level-1 {
  background-color: #ebd1ef;
}

#fm-horizontal-headers-scroll div[id^="col-header-"] .title-label-level-2 {
  background-color: #cceae7;
}

#fm-horizontal-headers-scroll div[id^="col-header-"] .title-label-level-3 {
  background-color: #fcf9d0;
}

.factors-tree-popover {
  max-height: 400px;
  width: unset;
  max-width: unset;
  overflow-x: hidden;
  overflow-y: auto;

  .rc-tree {
    padding: 0 10px;

    li span.rc-tree-iconEle {
      width: 0;
    }
  }
}

/* Фильтр событий */
#filter-form {
  width: 1100px;
  height: 700px;
  overflow-y: auto;
  margin: 0;
}

#filter-form .form-group {
  margin-right: 0;
  margin-left: 0;
}

#filter-form .removable-group-predicate {
  display: inline-block;
  width: 96%;
}

#filter-form .removable-predicate {
  display: inline-block;
  width: 800px;
}

#filter-form .remove-btn {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 5px;
}

#filter-form .container {
  width: 100%;
}

#filter-form .filter-group-predicate {
  padding: 3px;
  border-radius: 3px;
  border: 1px solid lightblue;
}

#filter-form .button-bar {
  margin: 2px 0 5px 0;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  background-color: #f8f8f8;
}

#filter-form .filter-group-predicate .button-bar .button-bar-item {
  margin: 0 5px;
}

.trend-color-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
}

.trend-color-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.trend-color-popover {
  position: absolute;
  z-index: 3;
}

.trend-color-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.color-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
}

.color-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.color-popover {
  position: absolute;
  z-index: 2;
}

.color-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*  */
.c-dropdown-tree-select .dropdown-trigger {
  border-radius: 0.25rem;
}

.c-dropdown-tree-select .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.c-dropdown-tree-select .dropdown-content .checkbox-item {
  vertical-align: middle;
  margin: 0 10px 3px 0;
}

.c-dropdown-tree-select .dropdown-content .toggle {
  margin-bottom: 3px;
}

.c-dropdown-tree-select .dropdown-content .toggle:after {
  content: " ";
}

.c-dropdown-tree-select .dropdown-content .toggle.collapsed:after {
  cursor: pointer;
  content: "+";
  font-weight: bold;
  font-size: 12pt;
}

.c-dropdown-tree-select .dropdown-content .toggle.expanded:after {
  cursor: pointer;
  content: "-";
  font-weight: bold;
  font-size: 12pt;
}

.retrospection-modal .modal-body {
  height: 700px;
  overflow-y: scroll;
}

.react-select-box.container {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 30px;
  height: 34px;

  .output-container {
    position: relative;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    padding: 0 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    background-color: #fff;
    border: #ccc 1px solid;
    border-radius: 4px;

    .output {
      height: 100%;
      width: calc(100% - 46px);
      box-shadow: none;
      border: none;
    }
  }

  .controls {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin: 0 3px 0 0;
  }

  .toggle {
    width: 25px;
    margin-right: 0;
    text-align: center;
    vertical-align: middle;
    border: none;
    border-left: #ccc 1px solid;
    height: 100%;
    background-color: #fff;

    &:after {
      content: none;
    }

    & i {
      font-family: RwWidgets;
      font-size: 14px;
      font-style: normal;

      &:before {
        content: '\E803';
      }
    }
  }

  .clear {
    width: 28px;
    margin-right: 0;
    text-align: center;
    vertical-align: middle;
    border: none;
    height: 100%;
    background: transparent;

    i {
      font-size: 14px;
      font-style: normal;

      &:before {
        content: 'x';
      }
    }
  }
}

.react-select-box {
  .select-container {
    padding: 5px 10px 5px 10px;
    top: 104%;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    background-color: #fff;
    border: #ccc 1px solid;
    border-radius: 4px;
    position: absolute;
    display: block;
    z-index: 10;
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
  }

  .list {
    padding: 0 0 0 0;
    list-style-type: none;

    .list-item {
      white-space: nowrap;
      line-height: 30px;
      max-height: 30px;

      label {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .checkbox-wrapper {
        display: inline;
        margin: 0 5px 0 0;
        vertical-align: top;
        height: 100%;

        input[type="checkbox"] {
          margin-top: 9px;
          vertical-align: top;
        }
      }
    }
  }
}

.no-select {
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tape-period-filter-wrapper {
  vertical-align: top;

  .rw-combobox {
    display: inline-block;
    white-space: nowrap;
    width: 60%;
  }

  .btn {
    vertical-align: top;
  }
}

form.tape-filter {
  margin-left: -15px;
  margin-right: -15px;
}

.tape-filter {
}

.react-contextmenu {
  min-width: unset;
}

.react-contextmenu-item {
  &:hover {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;

    .react-contextmenu-submenu {
      position: absolute;
      display: inline;
      list-style: none;
      left: 100%;
    }
  }

  .react-contextmenu-submenu {
    display: none;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    outline: none;
    transition: opacity .25s ease !important;
    z-index: 10001;
  }
}

.operation-report-modal {
  .content {
    width: 400px;
    height: 400px;
    max-height: 400px;
    overflow-y: auto;

    .error {
      color: red;
    }

    .warning {
      color: yellow;
    }

    .success {
      color: green;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1280px) {

}

@media (min-width: 1920px) {

  html {
    body {
      font: 11pt Roboto, Helvetica, sans-serif;
    }
  }

  .application-menu {
    font-size: 15px;
  }

  .rw-combobox {
    line-height: 30px;

  }

  .rw-widget-picker {
    height: 30px;

    .rw-dropdown-list-autofill, .rw-filter-input, .rw-input {
      padding: 0 12px;
    }

  }

  .alert-item {
    .help-button {
      padding: 5px 12px;
    }
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-control {
    height: 32px;
    padding: 6px 12px;
  }

  .form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
    padding-top: 4px;
  }

  .input-group {
    .form-control {
      border-radius: 2px;
      height: 32px;
      padding: 2px 12px;
    }

    .input-group-btn {
      button {
        height: 32px;
      }
    }
  }

  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
  }

  .input-group-btn {
    .glyphicon {
      top: 1px;
    }
  }

  .action-button {
    height: 35px;
    width: 160px;
    font-size: 14px;

    &.icon {
      .glyphicon {
        line-height: 33px;
        width: 33px;

      }

      .label-wrapper {
        width: 127px;
      }

      label {
        line-height: 33px;
      }
    }
    &.btn-success {
      border-color: #28a745;
    }
    &.btn-danger {
      border-color: #dc3545;
    }
  }

  .rc-tree-select {
    height: 32px;
    outline: none;

    .rc-tree-select-selection {
      height: 32px;

      &__rendered {
        line-height: 32px;
      }

      &__clear {
        top: 8px;
      }
    }

    &-arrow {
      height: 28px;
    }
  }

  .news-item {
    padding: 5px;
    .project-title {
      font-size: 14px;
      color: rgb(128, 128, 128);
    }

    &.action {
      background: #d20f0f38;
    }

    &.info {
      background: rgba(15, 116, 210, 0.22);
    }

    &.warning {
      background: rgba(210, 152, 15, 0.22);
    }

    .data {
      margin: 5px 0 10px 0;
      line-height: 20px;
      vertical-align: middle;

      & > div {
        padding: 0;
      }

      .source {
        font-size: 16px;
      }

      .type {
        font-size: 14px;
        padding: 0 0 0 5px;
      }

      .title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 220px;
      }
    }

    .additional-data {
      font-size: 12px;
      color: rgb(128, 128, 128);
    }
  }

  #global_tape_widget .react-scrollable-list {
    width: 100%;
    min-height: 600px;
    max-height: 700px;
  }

  #global_tape_widget .tape-item {
    border: 0 solid #e8e8e8;
    border-bottom-width: 2px;
    width: 100%;
  }

  #global_tape_widget .tape-item .tape-item-grid {
    width: 100%;
    padding: 0;
  }

  #global_tape_widget .tape-item .tape-item-row {
    margin: 2px 0;
  }

  #global_tape_widget .tape-item .project-label {
    color: rgb(128, 128, 128);
  }

  #global_tape_widget .tape-item .type-label {
    color: rgb(92, 149, 216);
    font-size: 14px;
  }

  #global_tape_widget .tape-item .title-label {

  }

  #global_tape_widget .tape-item .author-label {
    color: rgb(128, 128, 128);
    word-wrap: unset;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  #global_tape_widget .tape-item .date-label {
    color: rgb(128, 128, 128);
  }

  .home-view {
    .description {
      width: 100%;
      height: calc(100% - 10px);
      margin-top: 10px;
      resize: none;
      border: 1px solid #e8e8e8;
      padding: 5px;
    }
  }
}

.col-md-1 {
  .rw-input {
    padding: 0 6px;
  }
}

.description-wrapper {
  max-height: 100%;
  overflow-y: auto;
}

.rw-widget-picker>.rw-select {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}