.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  float: none;
  height: 5px;
  width: 100%;
  background-color: transparent;

  .progress {
    position: relative;
    margin: 0;
    border-radius: 0;
    background-color: #354052;
    height: 100%;
    width: 0;
  }
}

.react-scrollable-list {
  .loader {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    height: 100%;

    &.loading {
      display: block;
      pointer-events: none;
    }

  }
}

.toolbar {

  .navbar-nav {
    & > li > a {
      padding: 9px 0 8px;
    }

    li {
      &:nth-child(n+8) {
        display: none;
      }
    }

    .dropdown {
      display: inline-block;

      .dropdown-toggle {
        color: #f5f7f6;
        border: none;
        background-color: #7a8aa5;
        margin: 9px 2px 8px;
        height: 29px;
        line-height: 16px;
      }
    }
  }

  @media (min-width: 1380px) {
    .navbar-nav {
      li {
        &:nth-child(n+8) {
          display: inherit;
        }

        &:nth-child(n+9) {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1450px) {
    .navbar-nav {
      li {
        &:nth-child(n+9) {
          display: inherit;
        }

        &:nth-child(n+10) {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1620px) {
    .navbar-nav {
      li {
        &:nth-child(n+10) {
          display: inherit;
        }

        &:nth-child(n+11) {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1730px) {
    .navbar-nav {
      li {
        &:nth-child(n+11) {
          display: inherit;
        }

        &:nth-child(n+12) {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1850px) {
    .navbar-nav {
      li {
        &:nth-child(n+12) {
          display: inherit;
        }
      }

      .dropdown {
        display: none;
      }
    }
  }
}