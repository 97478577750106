html {
  width: 1280px;
  height: 720px;

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .root {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;

      #app {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
      }

      .application-menu {
        flex-basis: 14%;
        flex-shrink: 0;
        width: 14%;
      }

      .application-content {
        flex-basis: 85%;
        flex-shrink: 1;
        flex-grow: 1;
        position: relative;

        .projects-list-wrapper {
          flex-basis: 65%;
          flex-shrink: 1;
          flex-grow: 1;

          .projects-list {
            .projects-row {
              height: 70%;
              max-height: 70%;
              overflow-y: auto;
            }

            .description-row {
              height: calc(30% - 60px);
              max-height: calc(30% - 60px);
              overflow-y: auto;
            }
          }
        }

        .table-wrapper {
          overflow-y: auto;
        }

        .tab-content {
          height: 100%;

          .table-wrapper {
            max-height: calc(100% - 50px);
          }
        }

        .global-tape-widget-wrapper {
          flex-basis: 35%;
          flex-shrink: 1;
          flex-grow: 1;

          .list-row {
            height: 88%;
          }
        }

        .view {
          width: 100%;
          height: 100%;

          .row.view-content {
            height: 100%;
            margin-right: 0;
            padding-right: 0;
          }

          [class*="col-"].view-content {
            height: 100%;
            margin-right: 0;
            padding-right: 0;
          }

          .tabs-row {
            height: calc(37vh - 40px);
            max-height: calc(37vh - 40px);

            .tab-content {
              height: 100%;
              max-height: 100%;

              .tab-pane {
                height: calc(100% - 42px);
                max-height: calc(100% - 42px);
              }
            }
          }

          .project-form .tabs-row {
            height: calc(100% - 373px);
            max-height: calc(100% - 373px);
          }

          .factor-form .tabs-row {
            height: calc(100% - 373px);
            max-height: calc(100% - 373px);
          }

          /*.event-form .tabs-row {
            height: calc(100% - 340px);
            max-height: calc(100% - 340px);
          }*/

          .scenario-form .tabs-row {
            height: calc(100% - 293px);
            max-height: calc(100% - 293px);
          }

          .trend-form .tabs-row {
            height: calc(100% - 450px);
            max-height: calc(100% - 450px);
          }
        }

        .row.toolbar,
        .form-group.toolbar {
          height: 54px;
          margin-bottom: 5px;

          nav {
            margin-bottom: 0;
          }
        }

        .row.content {
          height: calc(100% - 60px);
        }

        .row.buttons-bar,
        .form-group.buttons-bar {
          height: 35px;
        }
      }

      .right-toggle-bar {
        display: flex;

        & > div {
          width: calc(100% - 25px);
        }
      }

      .right-sidebar {
        margin: 0;
        padding: 0;
        position: relative;
        flex-basis: 1%;
        flex-shrink: 0;
        flex-grow: 0;

        .overlay.content {
          position: absolute;
          width: 400px;
          top: 0;
          right: 45px;
          left: auto !important;

          .row.content {
            height: calc(100% - 70px);
          }
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1280px) {
  html {
    width: 100%;
    height: 100%;



    body {

      .news-filter-popover {
        min-width: 400px;
      }

      .root {
        #app {
        }

        .application-menu {
        }



        .application-content {
          .projects-list-wrapper {
            .projects-list {
              .projects-row {
              }

              .description-row {
              }
            }
          }

          .global-tape-widget-wrapper {
            .list-row {
            }
          }

          .view {
            .row.view-content {
            }

            [class*="col-"].view-content {
            }

            .tabs-row {
              .tab-content {
                .tab-pane {
                }
              }
            }

            /*.project-form .tabs-row {
              height: calc(100% - 357px);
              max-height: calc(100% - 357px);
            }
            .factor-form .tabs-row {
              height: calc(100% - 357px);
              max-height: calc(100% - 357px);
            }
            .event-form .tabs-row {
              height: calc(100% - 340px);
              max-height: calc(100% - 340px);
            }
            .scenario-form .tabs-row {
              height: calc(100% - 293px);
              max-height: calc(100% - 293px);
            }*/

            .trend-form .tabs-row {
              height: calc(100% - 450px);
              max-height: calc(100% - 450px);
            }
          }

          .row.toolbar,
          .form-group.toolbar {
            nav {
            }
          }

          .row.content {
          }

          .row.buttons-bar,
          .form-group.buttons-bar {
          }
        }
      }
    }
  }

  .modal-lg {
    width: 1010px;
  }
}

@media (min-width: 1920px) {
  html {
    body {
      .root {
        #app {
        }

        .application-menu {
        }

        .application-content {
          .projects-list-wrapper {
            .projects-list {
              .projects-row {
              }

              .description-row {
              }
            }
          }

          .global-tape-widget-wrapper {
            .list-row {
            }
          }

          .view {
            .row.view-content {
            }

            [class*="col-"].view-content {
            }

            .tabs-row {
              .tab-content {
                .tab-pane {
                }
              }
            }

            .project-form .tabs-row {
              height: calc(100% - 439px);
              max-height: calc(100% - 439px);
            }

            .factor-form .tabs-row {
              height: calc(100% - 439px);
              max-height: calc(100% - 439px);
            }

            /*.event-form .tabs-row {
              height: calc(100% - 487px);
              max-height: calc(100% - 487px);
            }*/

            .scenario-form .tabs-row {
              height: calc(100% - 382px);
              max-height: calc(100% - 382px);
            }

            .trend-form .tabs-row {
              height: calc(100% - 540px);
              max-height: calc(100% - 540px);
            }
          }

          .row.toolbar,
          .form-group.toolbar {
            nav {
            }
          }

          .row.content {
          }

          .row.buttons-bar,
          .form-group.buttons-bar {
          }
        }
      }
    }
  }
}