.dirty-indicator {
  height: 32px;
  line-height: 32px;
  float: right;
  margin-right: 1rem;
  color: gray;

  &.success {
    .glyphicon {
      margin-right: 2px;
      color: #5cb85c
    }
  }
}