.comments-tab {

  .c-grid {
    width: 700px;
    margin: 0;
    padding: 0;
  }

  .c-row {
    margin: 10px 0;
  }

  .c-list {
    border: 2px solid #e8e8e8;
    margin-bottom: 0;
  }

  .comment-item {
    border: 0 solid #e8e8e8;
    border-bottom-width: 2px;
    width: 100%;

    .comment-item-grid {
      width: 100%;
      padding: 0;
    }

    .comment-item-row {
      margin: 2px 0;
    }

    .icon-button {
      height: 25px;
      width: 25px;
      background-color: transparent;
      border-width: 0;
      cursor: pointer;
    }

    .author-label {
      color: rgb(92, 149, 216);
      font-size: 16px;
    }

    .date-label {
      color: rgb(128, 128, 128);
    }

    .comment-label {

    }

    .last-edit-label {
      color: rgb(128, 128, 128);
      font-style: italic;
      font-size: 12px;
    }
  }

  .react-scrollable-list-item {
    border: none;
  }

  .discussion-row {
    height: calc(100% - 142px);
    max-height: calc(100% - 142px);
  }
}